import ArrowRight from 'assets/arrowRight.svg';
import {useState} from "react";
import {toggleDrawer} from "components/Drawer";

interface Props {
  label: string;
}

export function RoundedButton(props: Props) {
  const [active, setActive] = useState(false);

  const onClick = () => {
    setActive(true);
    setTimeout(() => setActive(false), 1000);
    toggleDrawer(true);
  }

  return (
    <div className={'roundedButton' + (active ? ' active' : '')}
         onClick={onClick}
    >
      <div>
        <img src={ArrowRight} alt="arrow right"/>
      </div>
      <span>{ props.label }</span>
    </div>
  )
}
