import {Input, InputType} from "components/Input";
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import {drawerIsOpen, toggleDrawer} from "components/Drawer";
import {useTranslation} from "react-i18next";
import CheckIcon from 'assets/checkIcon.svg';
import * as Yup from 'yup';
import {PoliticLink} from "components/PoliticLink";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, '_')
    .max(50, '_')
    .required('_'),
  tel: Yup.string()
    .min(12, '_')
    .max(12, '_')
    .required('_'),
  email: Yup.string()
    .email('_')
    .required('_'),
  message: Yup.string().max(255, '_')
});

const defaultForm = {
  name: '',
  tel: '',
  email: '',
  message: '',
}

export function Form() {
  const { t: dT } = useTranslation();
  const t = (v) => dT(v).toString();

  const [isSuccess, setIsSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const form = useFormik({
    initialValues: defaultForm,
    validationSchema: ValidationSchema,
    validateOnChange: false,
    onSubmit: (data) => {
      setDisabled(true);

      fetch('/php/send.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(t => {
          console.log('!! THEN', t);
          setIsSuccess(true);
        })
        .catch(e => {
          console.log('CATCH ', e)
        })
        .finally(() => setDisabled(false));
    },
  });

  const { values, errors, handleChange } = form;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!drawerIsOpen()) {
        form.resetForm();
      }
    }, 1000)

    return () => clearInterval(interval);
  }, [])

  const close = () => {
    toggleDrawer(false);
    setTimeout(() => setIsSuccess(false), 500);
    form.resetForm();
  }

  return (
    <div id="form">
      {!isSuccess && (
        <>
          <h3>{t('form.title')}</h3>

          <div>
            <form className="form" onSubmit={e => !disabled && form.handleSubmit(e)}>
              <Input id="name"
                     value={values.name}
                     onChange={handleChange}
                     label={t('form.name')}
                     error={!!errors.name}
              />
              <Input id="tel"
                     value={values.tel}
                     onChange={handleChange}
                     label={t('form.telephone')}
                     error={!!errors.tel}
                     type={InputType.phone}
              />
              <Input id="email"
                     value={values.email}
                     onChange={handleChange}
                     label={t('form.email')}
                     error={!!errors.email}
              />
              <Input id="message"
                     value={values.message}
                     onChange={handleChange}
                     label={t('form.message')}
                     type={InputType.textArea}
                     error={!!errors.message}
              />

              <button type="submit" className={disabled ? 'disabled' : ''}>{ t('form.getConsult') }</button>
            </form>

            <div className="politic">
              { t('form.politic1') }
              <br />
              <PoliticLink>{ t('form.politic2') }</PoliticLink>
            </div>
          </div>
        </>
      )}

      {isSuccess && (
        <div className="success">
          <div>
            <img src={CheckIcon} alt="check icon"/>

            <h3 dangerouslySetInnerHTML={{ __html: t('form.success') }} />
          </div>

          <button onClick={close}>{ t('form.close') }</button>
        </div>
      )}
    </div>
  )
}

