import {RoundedButton} from "components/RoundedButton";
import {CreateMarkup} from "components/CreateMarkup";
import {useTranslation} from "react-i18next";
import {TopBanner} from "components/TopBanner";

function HomeTop() {
  const { t } = useTranslation();

  return (
    <div className="homeTop">
      <div className="container block">
        <div className="homeTop-left">
          <div>
            <h1 dangerouslySetInnerHTML={CreateMarkup(t('headerTop.title'))} />

            <ul>
              <li>{t('headerTop.list1').toString()}</li>
              <li>{t('headerTop.list2').toString()}</li>
              <li>{t('headerTop.list3').toString()}</li>
            </ul>

            <div className="homeTop-button">
              <RoundedButton label={t('actions.leaveRequest')} />
            </div>
          </div>
        </div>

        <div className="homeTop-banner">
          <TopBanner />
        </div>
      </div>
    </div>
  )
}

export default HomeTop;
