import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import Banner1 from 'assets/banner1.png';
import Banner2 from 'assets/banner2.png';
import Banner3 from 'assets/banner3.png';

const images = [Banner1, Banner2, Banner3]
const images2 = [Banner1, Banner3, Banner2]

export function TopBanner() {

  return (
    <div className="topBanner">
      <Swiper
        loop
        speed={1000}
        modules={[Autoplay]}
        direction={"vertical"}
        allowTouchMove={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        {images.map((img, i) => (
          <SwiperSlide key={i}>
            <img src={img} alt="slide img" />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        dir="rtl"
        loop
        speed={1000}
        modules={[Autoplay]}
        direction={"vertical"}
        allowTouchMove={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          reverseDirection: true
        }}
      >
        {images2.map((img, i) => (
          <SwiperSlide key={i}>
            <img src={img} alt="slide img"/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
