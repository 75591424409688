import {useTranslation} from "react-i18next";
import {useCallback, useState} from "react";
import {CreateMarkup} from "components/CreateMarkup";
import {ServiceContent} from "components/ServiceContent";
import PrimaryArrowBottom from 'assets/PrimaryArrowBottom.svg';
import {Collapse} from "components/Collapse";
import {isMobile, ROOT_SCROLL_EL} from "../content/helpers";

const HEADER_HEIGHT = 67;

function Services() {
  const { t: dT } = useTranslation();
  const t = (v) => dT(v).toString();

  const [selectedIndex, setSelectedIndex] = useState(isMobile() ? null : 0);

  const setIndex = useCallback((index) => {

    if (isMobile()) {
      setTimeout(() => scrollToService(index), 400);
    }
    setSelectedIndex(isMobile() && selectedIndex === index ? null : index);
  }, [selectedIndex]);

  const scrollToService = (id) => {
    const element = document.getElementById('services-' + id);
    const elementPosition = element.getBoundingClientRect().top;
    const topOffset = ROOT_SCROLL_EL.scrollTop;
    ROOT_SCROLL_EL.scrollTo({ top: elementPosition + topOffset - HEADER_HEIGHT });
  }

  return (
    <div id="services">
      <div className="container services">
        <div className="services-menu">
          <span className="block-spoiler">{ t('services.spoiler') }</span>

          <div className="services-list">
            {Array.from({ length: 6}, (_, i) => i).map(id => (
              <div key={id} className="list-item" id={"services-" + id}>
                <div className={'services-list-item' + (selectedIndex === id ? ' selected' : '')}
                     onClick={() => setIndex(id)}
                >
                  <div>
                    <div className="content">
                      <span className="number">0{ id + 1 }/</span>
                      <span className="label" dangerouslySetInnerHTML={CreateMarkup(t(`services.items.${id}.title`))} />
                    </div>
                    <img className="mb-show" src={PrimaryArrowBottom} alt="arrow bottom"/>
                  </div>
                </div>
                <div className="mb-show">
                  <Collapse open={selectedIndex === id}>
                    <div className={`services-selected content-${selectedIndex}`}>
                      <ServiceContent serviceId={selectedIndex} open />
                    </div>
                  </Collapse>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="services-selected">
          <ServiceContent serviceId={selectedIndex} />
        </div>
      </div>
    </div>
  )
}

export default Services;
