interface Props {
  transparent?: boolean
}

export function Logo(props: Props) {
  return (
    <div className={'logo' + (props.transparent ? ' transparent' : '')}>
      <span>ACCENT</span>
      <span>CAPITAL</span>
    </div>
  );
}

