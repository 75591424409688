import React, {useCallback, useEffect, useRef, useState} from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import HomeTop from "./HomeTop";
import About from "./About";
import Services from "./Services";
import Contacts from "./Contacts";
import Feedbacks from "./Feedbacks";
import {Drawer} from "components/Drawer";
import {Breakpoints} from "../content/helpers";

function App() {
  const ref = useRef();
  const [zoom, setZoom] = useState(1);

  const onResize = useCallback(() => {
    const width = document.body.clientWidth;

    if (width > Breakpoints.over) {
      setZoom(Breakpoints.over / Breakpoints.max);
    } else if (width > Breakpoints.mobile) {
      setZoom(width / Breakpoints.max);
    } else if (width < Breakpoints.small) {
      setZoom(width / Breakpoints.small);
    } else {
      setZoom(1);
    }
  }, [])

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize, ref]);

  return (
    <>
      <div ref={ref} className="layout" style={{ zoom }}>
        <Header />
        <main>
          <HomeTop />
          <About />
          <Services />
          <Feedbacks />
          <Contacts />
        </main>

        <Footer />
      </div>

      <Drawer />
    </>
  );
}

export default App;
