import ScalesIcon from "assets/scalesIcon.svg";
import DepartmentIcon from "assets/departmentIcon.svg";
import HammerIcon from "assets/hammerIcon.svg";
import {RoundedButton} from "components/RoundedButton";
import {IncrementedNumber} from "components/IncrementedNumber";
import {useTranslation} from "react-i18next";

interface CounterItem {
  value: number;
  description: string;
  color: string;
  subTitle?: string;
  prefix?: string;
}

const DATA: CounterItem[] = [
  {
    value: 4000,
    description: 'about.counter1',
    subTitle: 'about.clients',
    color: '#399FFA'
  },
  {
    value: 30000000,
    description: 'about.counter2',
    color: '#FF7F5D',
    prefix: '$'
  },
  {
    value: 20,
    description: 'about.counter3',
    subTitle: 'about.years',
    color: '#FFDA45'
  }
]

function About() {
  const { t: dT } = useTranslation();
  const t = (v) => dT(v).toString();

  return (
    <div id="about">
      <div className="container about block">
        <div className="about-left">
          <div className="about-left-content">
            <span className="block-spoiler">{ t('about.spoiler') }</span>
            <h2 className="block-title">{ t('about.title') }</h2>

            <div className="block-list">
              <div className="block-list-item">
                <img src={ScalesIcon} alt="icon"/>
                <span>{ t('about.list1') }</span>
              </div>
              <div className="block-list-item">
                <img src={DepartmentIcon} alt="icon"/>
                <span>{ t('about.list2') }</span>
              </div>
              <div className="block-list-item">
                <img src={HammerIcon} alt="icon"/>
                <span>{ t('about.list3') }</span>
              </div>
            </div>

            <RoundedButton label={t('actions.askQuestion')} />
          </div>
        </div>

        <div className="about-right">
          {DATA.map((v, i) => (
            <div key={i} className="counter">
              <div className="counter-title">
                <h1>
                  &gt;{v.prefix}<IncrementedNumber number={v.value} />
                </h1>
                <span>{t(v.subTitle)}</span>
              </div>
              <p className="counter-description" dangerouslySetInnerHTML={{__html: t(v.description)}} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default About;
