import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import Quotes from "assets/quotes.svg";
import User1 from "assets/user1.png";
import User2 from "assets/user2.png";
import User3 from "assets/user3.png";

import "swiper/css/bundle";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

const feedbacks = [User1, User2, User3];

function Feedbacks() {
  const { t: dT } = useTranslation();
  const t = (v) => dT(v).toString();

  return (
    <div id="feedbacks" className="feedbacks">
      <div className="container">
        <span className="block-spoiler">{t("feedbacks.spoiler")}</span>
      </div>

      <div className="slider">
        <Swiper
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={105}
          freeMode={true}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {feedbacks.map((avatar, i) => (
            <SwiperSlide key={i}>
              <div className="slider-item">
                <img className="quotes" src={Quotes} alt="quotes" />
                <div className="avatar">
                  <img src={avatar} alt="avatar" />
                  <p className="slider-item-name mb-show">
                    {t(`feedbacks.items.${i}.name`)}
                  </p>
                </div>
                <div className="text">
                  <p className="slider-item-text">
                    {t(`feedbacks.items.${i}.text`)}
                  </p>
                  <p className="slider-item-name d-show">
                    {t(`feedbacks.items.${i}.name`)}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="shadow"></div>
    </div>
  );
}

export default Feedbacks;
