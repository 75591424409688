import AnimatedNumbers from "react-animated-numbers";
import {useEffect, useRef} from "react";
import {isMobile} from "content/helpers";

interface Props {
  number: number;
}

export function IncrementedNumber(props: Props) {
  const ref = useRef() as any;

  useEffect(() => {
    const interval = setInterval(() => {
      const items = ref.current.children[0].querySelectorAll('div');
      items.forEach((v) => {
        if (v.innerText === ',') {
          v.innerText = '.'
        }
      })
    }, 200)

    return () => clearInterval(interval);
  }, [ref])

  return (
    <div ref={ref}>
      <AnimatedNumbers
        includeComma
        fontStyle={isMobile() ? {} : {}}
        animateToNumber={props.number}
        configs={[
          { mass: 1, tension: 130, friction: 40 },
          { mass: 1, tension: 140, friction: 40 },
          { mass: 1, tension: 130, friction: 40 }
        ]}
      ></AnimatedNumbers>
    </div>
  )
}
