import {useEffect, useRef, useState} from "react";

interface Props {
  open: boolean;
  minHeight?: number;
  children: any;
}

export function Collapse({ open, minHeight, children }: Props) {
  const ref = useRef() as any;
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setHeight((ref as any).current?.scrollHeight);
    }, 200);
    return () => clearInterval(interval);
  }, [open])

  return (
    <div className={'collapse' + (open ? ' isOpen' : '')}
         style={{ '--H': height + 'px', minHeight } as any}
    >
      <div ref={ref} className="collapse-content" style={{ minHeight }}>
        {children}
      </div>
    </div>
  )
}
