import PlaceIcon from "assets/placeIcon.svg";
import MailIcon from "assets/mailIcon.svg";
import PhoneIcon from "assets/phoneIcon.svg";
import TlgBot from "assets/tlgBot.png";
import TlgBotStar from "assets/tlgBotStar.png";
import SocialIcon from "assets/socialArrow.svg";
import {useTranslation} from "react-i18next";
import {CreateMarkup} from "components/CreateMarkup";

interface SocialItem {
  href: string;
  title: string;
}

const SOCIALS: SocialItem[] = [
  {
    href: 'https://instagram.com/accentcapital',
    title: 'Instagram'
  },
  {
    href: 'https://www.facebook.com/profile.php?id=100085400253490',
    title: 'Facebook'
  },
  {
    href: 'https://vk.com/accentcapitallaw',
    title: 'Vkontakte'
  },
  {
    href: 'https://t.me/accentsupportbot',
    title: 'Telegram'
  },
  {
    href: 'https://wa.me/18294888888',
    title: 'WhatsApp'
  },
]

function Contacts() {
  const { t: dT } = useTranslation();
  const t = (v) => dT(v).toString();

  return (
    <div id="contacts">
      <div className="container block contacts">
        <div className="contacts-content">
          <span className="block-spoiler">{ t('contacts.spoiler') }</span>
          <h2 className="block-title">{ t('contacts.title') }</h2>

          <div className="block-list">
            <div className="block-list-item">
              <img src={PlaceIcon} alt="icon"/>
              <a target="_blank" href="https://www.google.ru/maps/place/Downtown+Center/@18.4507083,-69.9581388,16.62z/data=!4m9!1m2!2m1!1sav.+n%C3%BA%C3%B1ez+de+c%C3%A1ceres+esq+santo+domingo+10114!3m5!1s0x8ea5621daf4447a1:0xfd92d83ddc67e864!8m2!3d18.4509765!4d-69.9527804!15sCi9hdi4gbsO6w7FleiBkZSBjw6FjZXJlcyBlc3Egc2FudG8gZG9taW5nbyAxMDExNFowIi5hdiBuw7rDsWV6IGRlIGPDoWNlcmVzIGVzcSBzYW50byBkb21pbmdvIDEwMTE0kgEPc2hvcHBpbmdfY2VudGVymgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVVJEZGt4dVNHUkJFQUXgAQA">
                Av. Núñez de Cáceres esq, Santo Domingo 10114
              </a>
            </div>
            <div className="block-list-item">
              <img src={MailIcon} alt="icon"/>
              <a href="mailto:admin@accentlawfirm.com">admin@accentlawfirm.com</a>
            </div>
            <div className="block-list-item">
              <img src={PhoneIcon} alt="icon"/>
              <a href="callto:18294888888">+1 (829) 488-8888</a>
            </div>
          </div>

          <div className="socials">
            {SOCIALS.map((v, i) => (
              <a key={i} href={v.href} className="socials-item">
                <span>{v.title}</span>
                <img src={SocialIcon} alt="top-right arrow"/>
              </a>
            ))}
          </div>
        </div>

        <div className="contacts-img">
          <a className="star" href="https://t.me/accentsupportbot" target="_blank" rel="noreferrer">
            <img src={TlgBotStar} alt="tlg bot"/>
            <span dangerouslySetInnerHTML={CreateMarkup(t('contacts.runBot'))} />
          </a>
          <img className="bg" src={TlgBot} alt="tlg bot"/>
          <span className="time">
            {new Date().getHours()}
            <span className="time-divider">:</span>
            {new Date().getMinutes()}
          </span>
        </div>

        <div className="bg-shadow"></div>
      </div>
    </div>
  )
}

export default Contacts;
