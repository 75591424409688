import {useTranslation} from "react-i18next";
import {Logo, PoliticLink} from 'components';


function Footer() {
  const { t: dT } = useTranslation();
  const t = (v) => dT(v).toString();

  return (
    <footer>
      <div className="container footer">
        <Logo transparent />

        <div className="footer-info">
          <PoliticLink className="d-show pol">
            { t('footer.polSecurity') }<br />{ t('footer.personalData') }
          </PoliticLink>
          <PoliticLink className="mb-show pol">
            { t('footer.polSecurity') } { t('footer.personalData') }
          </PoliticLink>
          <span>Accent Capital, S.R.L. RNC-1-32-27853-4 <br /> © {new Date().getFullYear()} { t('footer.rights') }</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
