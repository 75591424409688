import {useCallback} from "react";
import InputMask from 'react-input-mask';

export enum InputType {
  input,
  textArea,
  phone
}

interface Props {
  id: string;
  label: string;
  value: string;
  onChange: (v: any) => void;
  error?: boolean;
  type?: InputType;
}

export function Input({id, label, value, onChange, error, type}: Props) {
  const renderField = useCallback(() => {
    if (type === InputType.textArea) {
      return (
        <textarea
          id={id}
          name={id}
          value={value || ""}
          onChange={onChange}
          rows={3}
          maxLength={255}
        />
      );
    }

    if (type === InputType.phone) {
      return (
        <InputMask id={id} name={id} mask="+99999999999" maskChar=" " value={value || ""} onChange={onChange} />
      )
    }

    return (
      <input
        id={id}
        name={id}
        value={value || ""}
        onChange={onChange}
        type="text"
      />
    );
  }, [type, id, value, onChange])

  return (
    <div className={'input' + (error ? ' error' : '')}>
      {renderField()}
      <label htmlFor={label}>{label}</label>
    </div>
  )
}
