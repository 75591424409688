import {useEffect, useRef} from "react";
import {Form} from "components/Form";

export const toggleDrawer = (isOpen: boolean) => {
  document.querySelector('.drawer').classList.toggle('isOpen', isOpen);
  document.body.classList.toggle('scroll-lock', isOpen);

  if (!isOpen) {
    setTimeout(() => document.querySelector('.drawer').classList.toggle('override', isOpen), 300);
  } else {
    document.querySelector('.drawer').classList.toggle('override', isOpen);
  }
}

export const drawerIsOpen = (): boolean => document.querySelector('.drawer').classList.value.includes('isOpen');

export function Drawer() {
  const ref = useRef() as any;

  const onClick = (e) => {
    if (!e.target.closest('.drawer-content')) {
      toggleDrawer(false);
    }
  }

  useEffect(() => {
    ref.current.addEventListener('click', onClick);

    return () => ref.current.removeEventListener('click', onClick);
  }, []);

  return (
    <div ref={ref} id="drawer" className="drawer">
      <div className="drawer-content">
        <div className="drawer-close">
          <svg onClick={() => toggleDrawer(false)} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="27.5" stroke="#465171"/>
            <path d="M36 36L20 20M36 20L20 36" stroke="#465171" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>

        <div className="drawer-content-inner">
          <Form />
        </div>
      </div>
    </div>
  )
}

