import {useTranslation} from "react-i18next";


export function PoliticLink(props) {
  const { i18n } = useTranslation();

  return (
    <a className={props?.className || ''} href={`/files/Politics_${i18n.resolvedLanguage}.docx`} download>
      { props.children }
    </a>
  )
}
