import {CreateMarkup} from "components/CreateMarkup";
import {RoundedButton} from "components/RoundedButton";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import { Collapse } from "components/Collapse";

interface Props {
  serviceId: number;
  open?: boolean;
}

const TABS = ['services.tab1', 'services.tab2']

export function ServiceContent({ serviceId, open }: Props) {
  const { t: dT } = useTranslation();
  const t = (v) => dT(v).toString();

  const [isFull, setIsFull] = useState(open || false);
  const [activeTab, setActiveTab] = useState(0);

  const service = (key) => t(`services.items.${serviceId}.${key}`);
  const content = CreateMarkup(service(`content.${activeTab}`));

  return (
    <div className="serviceContent">
      <span className="serviceContent-id">0{ serviceId + 1 }/</span>
      <h2 dangerouslySetInnerHTML={CreateMarkup(service('titleFull'))}></h2>
      <RoundedButton label={t('actions.leaveRequest')} />
      <p className="serviceContent-description">{service('description')}</p>

      <div className={`serviceContent-main ${isFull ? 'isOpen' : ''}`}>
        <div className={`tabs active-${activeTab}`}>
          {TABS.map((label, i) => (
            <div key={i}
                 className={'tab' + (activeTab === i ? ' active' : '')}
                 onClick={() => setActiveTab(i)}
            >
              {t(label)}
            </div>
          ))}
        </div>

        <Collapse open={isFull} minHeight={190}>
          <div className="tab-content" dangerouslySetInnerHTML={content} />
        </Collapse>

        <div className="shadow"></div>
        <div className={'expand' + (isFull ? ' isOpen' : '')} onClick={() => setIsFull(!isFull)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 9L12 17L20 9" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  )
}
