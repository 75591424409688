
export enum Breakpoints {
  over = 2600,
  max = 1400,
  mobile = 750,
  small = 375
}

export const isMobile = () => (window.innerWidth < Breakpoints.mobile);

export const ROOT_SCROLL_EL = document.querySelector('#root');

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
