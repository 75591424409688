import {useTranslation} from "react-i18next";
import {Logo} from "components/Logo";
import {useCallback, useEffect, useState} from "react";
import {Collapse} from "components/Collapse";
import {toggleDrawer} from "components/Drawer";

const languages = [
  { key: 'ru-RU', label: 'RUS' },
  { key: 'en-EN', label: 'ENG' },
  { key: 'es-ES', label: 'ES' },
]

function Header() {
  const { t: dT, i18n } = useTranslation();
  const t = (v) => dT(v).toString();

  const [currentLanguage, setCurrentLanguage] = useState(languages.find(v => v.key === i18n.language) || languages[0]);
  const [lngSwitchIsOpen, setLngSwitchIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenuIsOpen(!menuIsOpen);
    document.body.classList.toggle('scroll-lock', !menuIsOpen);
  }, [menuIsOpen]);

  const handleLanguage = (lng) => {
    i18n.changeLanguage(lng.key).then(() => {
      setLngSwitchIsOpen(false);
      setCurrentLanguage(lng);
    });
  }

  useEffect(() => {
    const onClick = (e) => {
      if (!e.target.closest('#lng-picker')) {
        setLngSwitchIsOpen(false);
      }
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [])

  return (
    <header>
      <div className="container header">
        <div className="header-logo">
          <Logo />
          {!menuIsOpen && (
            <div className="language-picker">
              <div id="lng-picker" className={'language-picker' + (lngSwitchIsOpen ? ' isOpen' : '')}
                   onClick={() => setLngSwitchIsOpen(!lngSwitchIsOpen)}
              >
                <span>{currentLanguage.label}</span>
                <Collapse open={lngSwitchIsOpen}>
                  <div className="language-list">
                    {languages.filter(v => v.key !== currentLanguage.key).map(lng => (
                      <span key={lng.key} onClick={() => handleLanguage(lng)}>{lng.label}</span>
                    ))}
                  </div>
                </Collapse>
              </div>
            </div>
          )}
        </div>

        <div className="navigation">
          <nav>
            <ul>
              <li>
                <a href="#about">{ t('header.about') }</a>
              </li>
              <li>
                <a href="#services">{ t('header.services') }</a>
              </li>
              <li>
                <a href="#feedbacks">{ t('header.feedbacks') }</a>
              </li>
              <li>
                <a href="#contacts">{ t('header.contacts') }</a>
              </li>
            </ul>
          </nav>

          <button className="navigation-getConsult" onClick={() => toggleDrawer(true)}>{ t('actions.getConsult') }</button>
        </div>

        <div className={'burger-button' + (menuIsOpen ? ' isOpen' : '')} onClick={toggleMenu}></div>
      </div>

      <div className={'mobile-menu' + (menuIsOpen ? ' isOpen' : '')}>
        <div className="navigation">
          <nav>
            <ul>
              <li>
                <a href="#about" onClick={toggleMenu}>{ t('header.about') }</a>
              </li>
              <li>
                <a href="#services" onClick={toggleMenu}>{ t('header.services') }</a>
              </li>
              <li>
                <a href="#feedbacks" onClick={toggleMenu}>{ t('header.feedbacks') }</a>
              </li>
              <li>
                <a href="#contacts" onClick={toggleMenu}>{ t('header.contacts') }</a>
              </li>
            </ul>
          </nav>

          <div className="bottom">
            <button className="navigation-getConsult"
                    onClick={() => {
                      toggleMenu();
                      toggleDrawer(true)
                    }}
            >
              { t('actions.getConsult') }
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;
